<template>
    <FalconRankIrm
        class=""
        :class="{
            'before:text-content-positive': tendencyType === '+',
            'before:text-content-negative': tendencyType === '-',
            'before:font-mono_bold before:absolute before:left-auto before:right-[calc(50%-2.5em)] before:top-[0.5em] before:w-5 before:text-xs before:tracking-wide before:content-[attr(data-tendency)]':
                tendencyType === '+' || tendencyType === '-'
        }"
        :competitor
        :data-tendency="tendencyAttr"
        style=""
    ></FalconRankIrm>
</template>
<script setup lang="ts">
import { FalconRankIrm } from "@swisstiming/falcon-core";
import { computed } from "vue";

import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";

const props = defineProps<{
    competitor?: IAthCompetitorDetail;
}>();

const tendencyType = computed(() => props.competitor?.Stats?.Live_Tendency);
const tendencyVal = computed(() => props.competitor?.Stats?.Live_TendencyValue);

const tendencyAttr = computed(() => {
    switch (tendencyType.value) {
        case "+":
            return `▲${tendencyVal.value ?? ""}`;
        case "-":
            return `▼${tendencyVal.value ?? ""}`;
    }
    return undefined;
});
</script>
