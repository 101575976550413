import { isFunction, merge } from "lodash-es";
export interface IPushClientOptions {
    host?: string;
    username?: string;
    userpw?: string;
    frontendIdentifier?: string;
    authorizationUsername?: string;
    authorizationPassword?: string;
    alternatePort?: number;
    defaultPort?: number;
    autoConnect?: boolean;
    cachingClusterURL?: string;
    configPath?: string;
    useHTTPS?: boolean;
    useCurrentHost?: boolean;
    defaultNamespace?: string;
    connectTimeout?: number;
    debugLevel?: number;
}

export type CLIENT_EVENTS =
    | "connected"
    | "disconnected"
    | "reconnected"
    | "connectFailed"
    | "channelNotInitialized"
    | "channelInit"
    | "channelUpdate"
    | "uploadAuthorization";

class PushServerClientManager {
    pushClientInstance;

    /**
     * On construction try to set at least the "defaultNameSpace" in order to have the client connect to a default namespace
     * @param pushClientLib > The library instance either retrieved via require or import.
     * @param clientOptions > clientoptions object according to client documetation
     */
    connect(pushClientLib, clientOptions: IPushClientOptions = {}): any {
        this.pushClientInstance = new pushClientLib(merge(defaultPushClientOptions, clientOptions));

        console.info("PushClient Version: " + this.pushClientInstance.Version);

        // @ts-ignore
        if (!globalThis?.process) window.pushClientInstance = this.pushClientInstance;

        this.registerEvent("connected", () => {
            console.log("connected");
        });

        this.registerEvent("disconnected", () => {
            console.log("disconnected");
        });

        this.registerEvent("connectFailed", (err) => {
            console.log(err);
        });
        return this.pushClientInstance;
    }

    registerEvent(eventName: CLIENT_EVENTS, cb: (param1, param2, param3) => void): void {
        /**
         * Callback-parameters which are provided by the pushclient-events
         * [EVENT_NAME]: (param1, param2, param3)
         *
         * CONNECTED: ()
         * DISCONNETED: ()
         * CONNECT_FAILED: (error)
         * CHANNEL_NOT_INITIALIZED: (channelName)
         * CHANNEL_INIT: (channelName, data, version)
         * CHANNEL_UPDATE: (channelName, patch, sync)
         * UPLOAD_AUTHORIZATION: (success, namespace)
         *
         */

        if (isFunction(cb)) {
            this.pushClientInstance.on(eventName, (param1, param2, param3) => {
                cb(param1, param2, param3);
            });
        } else {
            if (!isFunction(cb)) console.warn("provided callback-function is not of type 'function': ", cb);
        }
    }

    getClientInstance() {
        return this.pushClientInstance;
    }
}

export const PSClientManager = new PushServerClientManager();

export const defaultPushClientOptions: IPushClientOptions = {
    alternatePort: 1030,
    defaultPort: 80,
    autoConnect: true,
    useCurrentHost: false,
    useHTTPS: true,
    defaultNamespace: "SPORTCODE_ENVIRONMENT",
    debugLevel: 0,
    connectTimeout: 5000
};

export const devPushClientOptions: IPushClientOptions = {
    ...defaultPushClientOptions,
    host: "ps-sync.origin.web.dev-sports.swisstiming.com",
    cachingClusterURL: "https://ps-cache.web.dev-sports.swisstiming.com/node/db/"
};

export const uatPushClientOptions: IPushClientOptions = {
    ...defaultPushClientOptions,
    host: "ps-lb.origin.web.uat-sports.swisstiming.com",
    cachingClusterURL: "https://ps-cache.web.uat-sports.swisstiming.com/node/db/"
};

export const prodPushClientOptions: IPushClientOptions = {
    ...defaultPushClientOptions,
    host: "ps-lb.origin.web.swisstiming.com",
    cachingClusterURL: "https://ps-cache.web.swisstiming.com/node/db/"
};

export const createDevPushClientOptions = (overwriteOptions: IPushClientOptions = {}): IPushClientOptions => {
    return { ...devPushClientOptions, ...overwriteOptions };
};

export const createUatPushClientOptions = (overwriteOptions: IPushClientOptions = {}): IPushClientOptions => {
    return { ...uatPushClientOptions, ...overwriteOptions };
};

export const createProdPushClientOptions = (overwriteOptions: IPushClientOptions = {}): IPushClientOptions => {
    return { ...prodPushClientOptions, ...overwriteOptions };
};

export const getApplicationName = (sportcode: string, environment: string, prefix?: string, suffix?: string): string =>
    `${prefix ? prefix + "_" : ""}${sportcode}_${environment}${suffix ? "_" + suffix : ""}`;
