<template>
    <div v-if="sortedPdfs?.length" :class="pt?.content">
        <span :class="pt?.header">{{ title }}</span>
        <ul v-if="sortedPdfs?.length" class="flex flex-wrap gap-2" :class="pt?.list">
            <li v-for="pdf in sortedPdfs" :key="pdf.Code" :class="pt?.item">
                <AthPdfHyperLink
                    :binary-name="pdf.FileName"
                    class="text-brand-secondary"
                    :title="pdf.Title"
                ></AthPdfHyperLink>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
interface IAthPdfListPassThrough {
    list?: string;
    content?: string;
    header?: string;
    item?: string;
}
import { IPdfEntry, objectToSortedArray } from "@swisstiming/webtec-kit";
import { computed, PropType } from "vue";

import AthPdfHyperLink from "./AthPdfHyperLink.vue";

const props = defineProps({
    title: String,
    pdfs: {
        type: Object as PropType<{ [pdfName: string]: IPdfEntry }>,
        default: () => {}
    },
    pt: { type: Object as PropType<IAthPdfListPassThrough>, default: () => {} }
});

const sortedPdfs = computed(() => objectToSortedArray(props.pdfs, "Index"));
</script>

<style scoped lang="scss"></style>
