import { computed, Ref } from "vue";

import { IRsc } from "../interfaces/channel.entities";
import { ICompetitionStructure, ICompetitionStructureEvent } from "../interfaces/channel/competition-structure";
import { RscCode } from "../rsc/dt.Rsc";
import { objectToSortedArray } from "../utils/array.utils";
import { useChannel } from "./channel.composables";

export const useCompStrucChannelData = (rawData: Ref<ICompetitionStructure>) => {
    const allEvents = computed<ICompetitionStructureEvent[]>(() => {
        if (rawData.value?.Events) {
            return Object.values(rawData.value?.Events).sort((a, b) => a.ListIndex - b.ListIndex);
        }
        return [];
    });

    /**
     * All events, phases and units sorted by ListIndex
     */
    const allCompetitionsSorted = computed(() =>
        allEvents.value.map((evt) => {
            return {
                ...evt,
                Phases: objectToSortedArray(evt.Phases).map((phase) => {
                    return {
                        ...phase,
                        Units: objectToSortedArray(phase.Units)
                    };
                })
            };
        })
    );

    const allEventsWithPools = computed(() => allEvents.value.filter((event) => event.Stats?.IsRoundRobin));

    const allUnitsByStartTime = computed(
        () =>
            allEvents.value
                .flatMap((event) => Object.values(event.Phases).flatMap((phase) => Object.values(phase.Units)))
                .sort((a, b) => a.StartTime - b.StartTime) ?? []
    );

    const sortedPdfs = computed(() => Object.values(rawData.value.Pdfs ?? {}).sort((a, b) => a.Index - b.Index));

    const getNodeEntries = (rsc: Ref<IRsc>) => {
        const event = computed(() => rawData.value.Events?.[rsc.value?.ValueEvent]);
        const phase = computed(() => event.value?.Phases?.[rsc.value?.ValuePhase]);
        const unit = computed(() => phase.value?.Units?.[rsc.value?.Value]);

        return { event, phase, unit };
    };

    const getNodeEntriesFromRsc = (rsc: Ref<string>) => {
        const rscDict = computed(() => {
            const rscClass = new RscCode(rsc.value);
            return {
                event: rscClass.ValueEvent,
                phase: rscClass.ValuePhase,
                unit: rscClass.IsUnit ? rsc.value : undefined
            };
        });

        const event = computed(() => rawData.value.Events?.[rscDict.value.event]);
        const phase = computed(() => event.value?.Phases?.[rscDict.value.phase]);
        const unit = computed(() => phase.value?.Units?.[rscDict.value.unit]);

        return computed(() => {
            return {
                event: event.value,
                phase: phase.value,
                unit: unit.value
            };
        });
    };

    return {
        rawData,
        allEvents,
        allEventsWithPools,
        allUnitsByStartTime,
        sortedPdfs,
        allCompetitionsSorted,
        getNodeEntries,
        getNodeEntriesFromRsc
    };
};

export const useCompStrucChannel = (compStrucChannelName: Ref<string>) =>
    useCompStrucChannelData(useChannel<ICompetitionStructure>(compStrucChannelName).content);
