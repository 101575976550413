<template>
    <div class="ath__live-page">
        <WtcChannel :channel-name="channelNames.schedule">
            <template #slot__result>
                <template v-if="hasRelevantUnits">
                    <div class="relative">
                        <div
                            class="bg-surface-alternate text-content/40 absolute right-2 flex items-center justify-center gap-2 place-self-end p-2 text-xs"
                        >
                            <FontAwesomeIcon class="text-[0.85em]" :icon="faClock"></FontAwesomeIcon>
                            <div class="w-12">{{ dateString }}</div>
                        </div>

                        <template v-for="(dayList, day) in unitsFilteredByTime" :key="day">
                            <div class="mt-5">
                                <div class="mb-1 text-xl">
                                    <WtcDateTime :date="day" format="DD.MM.YYYY"></WtcDateTime>
                                </div>
                                <AthLiveUnitList :unitsPerDay="dayList"></AthLiveUnitList>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else-if="tomorrowsUnits && tomorrowsUnits?.length > 0">
                    <p>{{ $getStrByLng().MESSAGE_TOMORROW_COMPETITIONS }}</p>
                    <WtcDateTime :date="tomorrowsCompetitionDay" format="DD.MM.YYYY"></WtcDateTime>

                    <AthLiveUnitList :unitsPerDay="unitsOfTomorrowsCompetitionDay"></AthLiveUnitList>
                </template>
                <WtcPlaceholder v-else :text="$getStrByLng().MESSAGE_NO_RUNNING_COMPETITIONS"></WtcPlaceholder>
            </template>
        </WtcChannel>
    </div>
</template>
<script setup lang="ts">
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useFalconStore } from "@swisstiming/falcon-core";
import {
    MINUTE,
    ScheduleStatus,
    TEN_MINUTES,
    useCurrentDayTime,
    useScheduleChannel,
    WtcChannel,
    WtcDateTime,
    WtcPlaceholder
} from "@swisstiming/webtec-kit";
import { useDateFormat } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, onUnmounted, ref } from "vue";

import AthLiveUnitList from "../components/elements/results/AthLiveUnitList.vue";
import { AthDisciplineTypes } from "../enums/ath.enums";
const { channelNames } = storeToRefs(useFalconStore());
const { unitsGroupedByDay, todaysCompetitionDay, tomorrowsCompetitionDay } = useScheduleChannel(
    computed(() => channelNames.value.schedule)
);

const { dateString } = useCurrentDayTime();

let currentTime = ref<number>(new Date().getTime());
// Test Data to test min time behavior: event=OG2024
// const currentTime = ref<number>(1723104360000 );
// const todaysCompetitionDay = ref("2024-08-08");

const minTime = computed(() => new Date(currentTime.value - TEN_MINUTES).getTime());

const currentSeconds = useDateFormat(new Date().getTime(), "s");
const timeToWait = (60 - +currentSeconds.value) * 1000;

let liveTimeInterval;
const liveTimeTimeOut = window.setTimeout(() => {
    currentTime.value = currentTime.value + MINUTE;
    liveTimeInterval = window.setInterval(() => {
        currentTime.value = currentTime.value + MINUTE;
    }, MINUTE);
}, timeToWait);

const relevantUnits = computed(() =>
    Object.entries(unitsGroupedByDay.value).filter(([dayKey, units]) => {
        if (dayKey === todaysCompetitionDay.value) {
            // The todays date is the one choosing a set of units, which could be displayed.
            return true;
        } else {
            // Include a day, which has at least any unit having a status which marks it as relevant.
            return units.some((unit) => {
                switch (unit.Status) {
                    case ScheduleStatus.GettingReady:
                    case ScheduleStatus.Running:
                    case ScheduleStatus.Unofficial:
                    case ScheduleStatus.FinalJudgeCheck:
                    case ScheduleStatus.ComingUp:
                        return true;
                    default:
                        return false;
                }
            });
        }
    })
);

const tomorrowsUnits = computed(() =>
    unitsGroupedByDay.value[tomorrowsCompetitionDay.value]?.filter(
        (unit) => unit.Stats?.Type !== AthDisciplineTypes.ROAD && unit.Stats?.Type !== AthDisciplineTypes.ROAD_TEAM
    )
);

const unitsOfTomorrowsCompetitionDay = computed(() => Object.groupBy(tomorrowsUnits.value, (item) => item.StartTime));

const unitsFilteredByTime = computed(() =>
    Object.fromEntries(
        relevantUnits.value?.map(([dayStr, units]) => [
            dayStr,
            Object.groupBy(
                units?.filter((unit) => {
                    if (
                        unit.Stats?.Type === AthDisciplineTypes.ROAD &&
                        unit.Stats?.Type === AthDisciplineTypes.ROAD_TEAM
                    ) {
                        return false;
                    } else if (dayStr === todaysCompetitionDay.value && unit.EndTime >= minTime.value) {
                        // If units end time is reached, it still needs to be bigger than the min time to be displayed and it needs to be part of todays date.
                        return true;
                    } else {
                        // Also include units, which are relevant due to their status values
                        switch (unit.Status) {
                            case ScheduleStatus.GettingReady:
                            case ScheduleStatus.Running:
                            case ScheduleStatus.Unofficial:
                            case ScheduleStatus.FinalJudgeCheck:
                            case ScheduleStatus.ComingUp:
                                return true;
                            default:
                                return false;
                        }
                    }
                }),
                (item) => item.StartTime
            )
        ])
    )
);

const hasRelevantUnits = computed(
    () =>
        Object.values(unitsFilteredByTime.value)
            .flatMap((values) => Object.values(values))
            .reduce((acc, arr) => acc + arr?.length, 0) > 0
);

onUnmounted(() => {
    window.clearTimeout(liveTimeTimeOut);
    window.clearInterval(liveTimeInterval);
});
</script>

<style scoped lang="scss"></style>
