<template>
    <div class="ath__result-header">
        <AthHeaderRecordsList
            :rec-code="unitTimingData.Stats?.RecCode"
            :records="unitTimingData.Records"
        ></AthHeaderRecordsList>
        <div class="max-mobile:flex-col flex justify-between">
            <div class="mb-2 flex flex-col">
                <div class="mt-4 leading-none">
                    <span class="mr-1 empty:mr-0">{{ useTranslatedProperty(unitTimingData, "PhaseName") }}</span>
                    <span class="mr-1 empty:mr-0">{{ useTranslatedProperty(unitTimingData, "UnitName") }}</span>
                </div>
                <div class="text-content/40 max-mobile:flex-col max-mobile:gap-1 flex text-sm">
                    <div class="flex items-center">
                        <WtcDateTime :date="unitTimingData.StartTime" format="dddd, DD. MMMM YYYY, HH:mm"></WtcDateTime>
                        <div v-if="unitTimingData.Stats?.Wind">
                            , <span>{{ $getStrByLng().LABEL_WIND }}</span>
                            <span>{{ unitTimingData.Stats?.Wind }}</span>
                        </div>
                    </div>

                    <div
                        class="max-mobile:flex-col max-mobile:items-start max-mobile:ml-0 max-mobile:gap-1 ml-5 flex items-center gap-5"
                    >
                        <div v-if="unitTimingData.Stats?.InOffTime" class="text-sm">
                            <span>{{ inOffLabel }}: </span>
                            <span>{{ unitTimingData.Stats?.InOffTime }}</span>
                        </div>

                        <div v-if="unitTimingData?.QualificationRule" class="text-sm">
                            <span class="mr-1">{{ $getStrByLng().LABEL_QUALIFIED }}</span>
                            <span>{{ unitTimingData.QualificationRule }}</span>
                        </div>

                        <FalconStatusBatch
                            class="max-mobile:-mx-1 !text-left"
                            :status-code="unitTimingData.Status as ScheduleStatus"
                        ></FalconStatusBatch>
                    </div>
                </div>
            </div>
            <div class="flex items-center gap-4">
                <AthPdfDropdown :rsc with-event with-phase with-unit></AthPdfDropdown>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { FalconStatusBatch, useTranslatedProperty } from "@swisstiming/falcon-core";
import { getStrByLng, ScheduleStatus, WtcDateTime } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import { useUnitTimingChannel } from "../../../composables/ath-data.composables";
import AthPdfDropdown from "../pdfs/AthPdfDropdown.vue";
import AthHeaderRecordsList from "../records/AthHeaderRecordsList.vue";
const props = defineProps<{
    rsc?: string;
}>();

const { unitTimingData } = useUnitTimingChannel(toRef(props, "rsc"));

const inOffLabel = computed(() => {
    const timeLabel = unitTimingData.value.Stats?.InOffLabel;
    if (timeLabel === "U") {
        return getStrByLng().LABEL_UNOFFICIAL_WINNING_TIME;
    }
    if (timeLabel === "O") {
        return getStrByLng().LABEL_OFFICIAL_WINNING_TIME;
    }
    return "";
});
</script>

<style scoped lang="scss"></style>
