<template>
    <FalconFrame v-if="config.isConfigLoaded">
        <template #header>
            <header class="max-laptop:flex-col flex items-center justify-between">
                <nav class="max-laptop:w-full">
                    <ul class="max-mobile:gap-0 mb-6 flex flex-wrap gap-2">
                        <AthRouterLink
                            class="max-mobile:basis-2/4"
                            :pt="{
                                active: 'bg-brand text-white',
                                content: 'flex items-center p-2 min-w-32 justify-center'
                            }"
                            :route-label="$getStrByLng().NAVIGATION_CURRENT"
                            :route-name="ATH_ROUTE_NAMES.LIVE"
                        >
                        </AthRouterLink>
                        <AthRouterLink
                            class="max-mobile:basis-2/4"
                            :pt="{
                                active: 'bg-brand text-white',
                                content: 'flex items-center p-2 min-w-32 justify-center'
                            }"
                            :route-label="$getStrByLng().NAVIGATION_SCHEDULE"
                            :route-name="ATH_ROUTE_NAMES.SCHEDULE"
                        >
                        </AthRouterLink>
                        <AthRouterLink
                            v-if="athConfig.isCupStandingsEnabled"
                            class="max-mobile:basis-2/4"
                            :pt="{
                                active: 'bg-brand text-white',
                                content: 'flex items-center p-2 min-w-32 justify-center'
                            }"
                            :route-label="$getStrByLng().NAVIGATION_CUP_STANDING"
                            :route-name="ATH_ROUTE_NAMES.CUP_STANDINGS"
                        >
                        </AthRouterLink>
                        <AthRouterLink
                            v-if="athConfig.isFlashQuotesEnabled"
                            class="max-mobile:basis-2/4"
                            :pt="{
                                active: 'bg-brand text-white',
                                content: 'flex items-center p-2 min-w-32 justify-center'
                            }"
                            :route-label="$getStrByLng().NAVIGATION_FLASH_QUOTES"
                            :route-name="ATH_ROUTE_NAMES.FLASH_QUOTES"
                        >
                        </AthRouterLink>
                        <AthRouterLink
                            v-if="athConfig.isPdfEnabled"
                            class="max-mobile:basis-2/4"
                            :pt="{
                                active: 'bg-brand text-white',
                                content: 'flex items-center p-2 min-w-32 justify-center'
                            }"
                            :route-label="$getStrByLng().NAVIGATION_PDF"
                            :route-name="ATH_ROUTE_NAMES.REPORTS"
                        ></AthRouterLink>
                    </ul>
                </nav>
                <div class="max-laptop:self-end max-laptop:mb-4 max-mobile:self-center flex items-center">
                    <div
                        v-if="athConfig.isConnectedStatusDisplayed"
                        class="mx-4 text-xs leading-none"
                        :class="{
                            ' text-emerald-500': connected === CONNECTION_STATUS.CONNECTED
                        }"
                    >
                        connected
                    </div>
                    <div class="bg-surface-alternate flex p-1 text-xs leading-none">
                        <button
                            class="p-2"
                            :class="{
                                'bg-brand text-white': timeZone === configuredTimeZone
                            }"
                            @click="setTimeZone(configuredTimeZone)"
                        >
                            {{ $getStrByLng().BTN_TIME_VENUE }}
                        </button>
                        <button
                            class="px-2 py-1"
                            :class="{ 'bg-brand text-white': timeZone === undefined }"
                            @click="setTimeZone(undefined)"
                        >
                            {{ $getStrByLng().BTN_TIME_LOCAL }}
                        </button>
                    </div>
                </div>
            </header>
        </template>
    </FalconFrame>
</template>
<script setup lang="ts">
import { FalconFrame } from "@swisstiming/falcon-core";
import { useFalconStore } from "@swisstiming/falcon-core";
import { CONNECTION_STATUS, LOCALISATION_SYMBOL, TIME_ZONE_OFFSET_SYMBOL } from "@swisstiming/webtec-kit";
import { toNumber } from "lodash-es";
import { storeToRefs } from "pinia";
import { computed, provide } from "vue";

import AthRouterLink from "./components/elements/AthRouterLink.vue";
import { ATH_ROUTE_NAMES } from "./enums/ath.enums";
import { useAthStore } from "./stores/ath.store";

const { connected, config } = storeToRefs(useFalconStore());

const { athConfig, timeZone } = storeToRefs(useAthStore());
const { setTimeZone } = useAthStore();
const { data } = storeToRefs(useFalconStore());

const configuredTimeZone = computed(() => toNumber(data.value.misConfigData?.TimeZoneOffset));
const localisation = computed(() => config.value.lng);

provide(TIME_ZONE_OFFSET_SYMBOL, timeZone);
provide(LOCALISATION_SYMBOL, localisation);
</script>

<style scoped lang="scss"></style>
