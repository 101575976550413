import { merge } from "lodash-es";
import { defineStore } from "pinia";
import {reactive, ref} from "vue";
import {handleError} from "./logging.store";

export const DEFAULT_LANGUAGE_CODE = "en";

export const useTranslationStore = defineStore("TranslationStore", () => {
    const stringsOfLng = reactive<{
        languageValues: { [strKey: string]: string };
    }>({ languageValues: {} });

    const setStrings = (lngStrings = {}) => {
        stringsOfLng.languageValues = merge({}, stringsOfLng.languageValues, lngStrings);
    };
    return { stringsOfLng, setStrings };
});

export const getStrings = () => useTranslationStore().stringsOfLng.languageValues;
export const getStrByLng = getStrings;

export const useAssetTranslations = (targetLng: string, defaultTranslations: object) => {
    const { setStrings } = useTranslationStore();
    const isTranslationRequestFinished = ref(false);

    const fetchTranslations = async () => {
        try {
            const locale = await fetch(`/localisation/${targetLng}.json`);
            const localeJson = await locale.json();

            if (targetLng !== DEFAULT_LANGUAGE_CODE) {
                const localeEn = await fetch(`/localisation/${DEFAULT_LANGUAGE_CODE}.json`);
                const localeEnJson = await localeEn.json();
                setStrings(merge(defaultTranslations, localeEnJson, localeJson));
            } else {
                setStrings(merge(defaultTranslations, localeJson));
            }
        } catch (err) {
            console.error(`Failed to request locales of given language: ${targetLng}`, err);
        } finally {
            isTranslationRequestFinished.value = true;
        }
    };

    fetchTranslations().catch(handleError);

    return { isTranslationRequestFinished };
};
