<template>
    <div class="ath__total-standing">
        <WtcChannel :channel-name="channelName" use-transition>
            <template #slot__result>
                <div class="animation-wrapper">
                    <AthHeaderRecordsList
                        :rec-code="timingData.Stats?.RecCode"
                        :records="timingData.Records"
                    ></AthHeaderRecordsList>
                    <div class="max-mobile:justify-start max-mobile:mt-3 mb-2 flex justify-end">
                        <AthPdfDropdown :rsc="eventRsc" with-event></AthPdfDropdown>
                    </div>
                    <div class="max-tablet:overflow-x-auto">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <!-- Rank -->
                                    <th class="sticky left-0 w-10 min-w-10">{{ $getStrByLng().HEADER_RANK }}</th>
                                    <!-- Bib -->
                                    <th class="w-10 min-w-10">{{ $getStrByLng().HEADER_BIB }}</th>
                                    <!-- Nat -->
                                    <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                                    <!-- Name -->
                                    <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                                    <!-- Time -->
                                    <th class="w-24 min-w-24">{{ $getStrByLng().HEADER_TOTAL_POINTS_RESULT }}</th>
                                    <!-- Discipline -->
                                    <th
                                        v-for="discipline in timingData.Splits"
                                        :key="discipline.Number"
                                        class="w-24 min-w-24"
                                    >
                                        {{ useTranslatedProperty(discipline, "Name") }}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="competitor in sortedResultList" :key="competitor.Id">
                                    <FalconRankIrm class="sticky left-0" :competitor="competitor"></FalconRankIrm>

                                    <td>{{ competitor.Bib }}</td>
                                    <td>
                                        <FalconFlag :nat-code="competitor.Nationality"></FalconFlag>
                                    </td>

                                    <AthNameColumn :competitor="competitor"></AthNameColumn>
                                    <AthResultColumn :competitor="competitor as IAthCompetitorDetail"></AthResultColumn>
                                    <td
                                        v-for="discipline in competitor.Intermediate"
                                        :key="discipline.Number"
                                        class="discipline"
                                    >
                                        <div class="flex items-center justify-center gap-2 pb-1">
                                            <span
                                                class="text-content/60 px-1 text-xs"
                                                :class="{
                                                    ['bg-surface-gold !text-content']: discipline.Rank === '1',
                                                    ['bg-surface-silver !text-content']: discipline.Rank === '2',
                                                    ['bg-surface-bronze !text-content']: discipline.Rank === '3'
                                                }"
                                                >{{ discipline.Rank }}</span
                                            >
                                            <div class="flex flex-col">
                                                <span class="font-normal_medium">{{ discipline.Result }}</span>
                                                <span class="text-content/60 text-xs">{{
                                                    discipline.SectorResult
                                                }}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </WtcChannel>
    </div>
</template>
<script setup lang="ts">
import { FalconFlag, FalconRankIrm, useTranslatedProperty } from "@swisstiming/falcon-core";
import { WtcChannel } from "@swisstiming/webtec-kit";
import { toRef } from "vue";

import AthPdfDropdown from "../../components/elements/pdfs/AthPdfDropdown.vue";
import AthHeaderRecordsList from "../../components/elements/records/AthHeaderRecordsList.vue";
import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import AthResultColumn from "../../components/elements/tables/AthResultColumn.vue";
import { useAthTimingChannel } from "../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../interfaces/ath.interfaces";

const props = defineProps({
    eventRsc: String
});

const { timingData, sortedResultList, channelName } = useAthTimingChannel(toRef(props, "eventRsc"));
</script>

<style scoped lang="scss"></style>
