export const useBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = "";
    let browserVersion = "";

    if (userAgent.includes("Firefox")) {
        browserName = "Firefox";
        browserVersion = userAgent.match(/Firefox\/([\d.]+)/)?.[1];
    } else if (userAgent.includes("Edg")) {
        browserName = "Edge";
        browserVersion = userAgent.match(/Edg\/([\d.]+)/)?.[1];
    } else if (userAgent.includes("Chrome")) {
        browserName = "Chrome";
        browserVersion = userAgent.match(/Chrome\/([\d.]+)/)?.[1];
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        browserName = "Safari";
        browserVersion = userAgent.match(/Version\/([\d.]+)/)?.[1];
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        browserName = "Opera";
        browserVersion = userAgent.match(/(Opera|OPR)\/([\d.]+)/)?.[2];
    }

    return { browserName, browserVersion };
};

export const isIOS = () => !!navigator.platform && /mac|ipad|iphone/.test(navigator.platform.toLowerCase());

export const getQueryParamDictionary = <T extends string>() =>
    Object.fromEntries(new URLSearchParams(window.location.search)) as {
        [key in T]: string;
    } & { [x: string]: string };

export const checkWebSocketSupport = () =>
    ("WebSocket" in window && !("__addTask" in WebSocket)) || "MozWebSocket" in window;

export const checkCookieSupport = () => navigator.cookieEnabled;

export const addScript = (src, callback?) => {
    const s = document.createElement("script");
    s.setAttribute("src", src);
    s.onload = callback;
    document.body.appendChild(s);
};
