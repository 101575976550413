import { useFalconStore } from "@swisstiming/falcon-core";
import { getFlashQuotesChannel, getScheduleChannel } from "@swisstiming/webtec-kit";
import { defineStore, storeToRefs } from "pinia";
import { computed, Ref, ref } from "vue";

import { ATH_PROFILES } from "../enums/ath.enums";
import { IAthleticsCisConfig } from "../interfaces/ath.interfaces";

export const useAthStore = defineStore("AthleticsResultsStore", () => {
    const { config: falconConfig, data } = storeToRefs(useFalconStore());

    const timeZone: Ref<number> = ref(undefined);
    const athCisConfig = computed(() => data.value.misConfigData as IAthleticsCisConfig);

    const athConfig = computed(() => {
        const profile = falconConfig.value?.profile?.toLowerCase() ?? athCisConfig.value.Setup?.Profile?.toLowerCase();
        return {
            isConnectedStatusDisplayed: profile !== ATH_PROFILES.WDL,
            isScheduleDisciplineFilterEnabled: profile !== ATH_PROFILES.WDL,
            isNavigationToOtherEventsEnabled: profile === ATH_PROFILES.WDL || profile === ATH_PROFILES.ZUERICH,
            isCupStandingsEnabled: athCisConfig.value.Buttons?.CupStandings,
            isFlashQuotesEnabled: athCisConfig.value.Buttons?.FlashQuotes,
            isCupsEnabled: athCisConfig.value.Buttons?.CupStandings,
            isPBnSBEnabled: athCisConfig.value.Config?.["PB/SB"],
            isMinorRecordsEnabled: athCisConfig.value.Config?.MinorRecords,
            isClubEnabled: athCisConfig.value.Config?.ShowClubs ?? false,
            isBibEnabled: athCisConfig.value.Config?.ShowBib ?? false,
            isScheduleByDisciplineEnabled: profile === ATH_PROFILES.DLV_CHAMPIONSHIP,
            isDiamondLeague: profile === ATH_PROFILES.WDL,
            isPdfEnabled: profile !== ATH_PROFILES.ZUERICH
        };
    });

    const channelNames = computed(() => {
        return {
            FlashQuotes: getFlashQuotesChannel(falconConfig.value.tournamentId),
            Schedule: getScheduleChannel(falconConfig.value.tournamentId),
            ScheduleByDiscipline: falconConfig.value.tournamentId
                ? `${falconConfig.value.tournamentId}_SCHEDULEEVENT_json`
                : undefined
        };
    });

    // Actions
    const setTimeZone = (newTimeZone: number) => {
        timeZone.value = newTimeZone;
    };

    return { channelNames, athConfig, setTimeZone, timeZone };
});
