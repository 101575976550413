<template>
    <WtcChannel :channel-name="flashQuotesChannelName" use-transition @update:channel="flashQuotesChan = $event">
        <template #slot__result>
            <div>
                <div
                    class="max-mobile:flex-col max-mobile:mb-4 max-mobile:items-start flex items-center justify-between"
                >
                    <div class="max-mobile:w-full max-mobile:flex-col max-mobile:mb-2 mb-5 flex items-start gap-5">
                        <WtcMultiSelect
                            v-model="selectedDisciplines"
                            :empty-message="getStrByLng().CONTROL_FILTER_BY_DISCIPLINE"
                            :max-display-options="3"
                            :options="groupedDisciplinesList"
                            :placeholder="getStrByLng().CONTROL_FILTER_BY_DISCIPLINE"
                            :pt="{
                                overlay: 'bg-surface border border-black/50 mt-1',
                                dropdown: 'ml-2',
                                root: 'border-content inline-flex items-center justify-between border bg-surface px-2 py-1 cursor-pointer select-none',
                                list: 'flex gap-6 text-sm p-3 py-2 pr-5 max-laptop:flex-col',
                                optionGroup:
                                    'border-l border-black/10 first:border-none pl-4 first:pl-0 py-1 max-laptop:pl-0 max-laptop:border-l-0 max-laptop:border-t max-laptop:pt-5 max-laptop:first:pt-1',
                                wtcList: '!grid-rows-6 max-mobile:grid-flow-row'
                            }"
                            scrollHeight="none"
                        >
                            <!-- use specific icons for wdl layout -->
                            <template v-if="config.profile === 'wdl'" #group-icon="{ allSelected, someSelected }">
                                <img :src="allSelected ? checkActive : someSelected ? checkPartial : checkEmpty" />
                            </template>
                            <template v-if="config.profile === 'wdl'" #item-icon="{ isSelected }">
                                <img :src="isSelected ? checkActive : checkEmpty" />
                            </template>
                        </WtcMultiSelect>

                        <Select
                            v-if="flashQuotesChan.ListDay?.length > 1"
                            v-model="dayFilter"
                            :options="flashQuotesChan.ListDay"
                            overlay-class="custom-select-overlay"
                            :placeholder="getStrByLng().CONTROL_FILTER_BY_DAY"
                            :pt="{
                                root: 'border-content border bg-surface cursor-pointer select-none w-44 relative inline-flex max-mobile:w-full'
                            }"
                            show-clear
                        />

                        <Select
                            v-if="languages?.length > 1"
                            v-model="lngFilter"
                            optionLabel="TranslatedName"
                            :options="translatedLanguages"
                            :placeholder="getStrByLng().CONTROL_FILTER_BY_LANGUAGE"
                        />
                    </div>
                    <div>
                        <AthPdfHyperLink
                            :binary-name="flashInterviewPdfName"
                            class="text-brand-secondary cursor-pointer"
                            :title="$getStrByLng().BTN_PDF_FLASH_QUOTES_SCREEN"
                        ></AthPdfHyperLink>
                    </div>
                </div>
                <ul class="max-laptop:grid-cols-1 grid grid-cols-2 gap-2">
                    <li v-for="interview in renderedInterviews" :key="interview.Id" class="bg-surface-alternate p-3">
                        <div class="flex h-full flex-col justify-between">
                            <div class="flex flex-col">
                                <WtcButton
                                    v-if="interview.Rsc"
                                    class="text-brand-secondary text-sm"
                                    :href="hrefToResult(interview.Rsc)"
                                    :text="
                                        useTranslatedProperty(
                                            flashQuotesChan?.Competitions?.[interview.Rsc.ValueEvent],
                                            'Name'
                                        )
                                    "
                                ></WtcButton>

                                <span class="font-normal_medium mt-2 text-lg leading-none">{{ interview.Title }}</span>
                                <article class="mb-4 mt-2 flex flex-col">
                                    <div class="font-normal_bold -mb-2 text-xl">”</div>
                                    <div class="leading-tight">{{ interview.Content }}</div>
                                </article>
                            </div>
                            <div class="flex flex-col justify-end">
                                <div class="font-normal_medium flex items-center text-[0.8em] leading-none">
                                    <FalconFlag :nat-code="interview.Nationality"></FalconFlag>
                                    <span class="ml-2">{{
                                        getCombinedValue(interview.FirstName, interview.Name, " ") ?? interview.Source
                                    }}</span>
                                </div>
                                <div class="text-content/40 mt-1 text-[0.8em] leading-none">
                                    <span
                                        ><WtcDateTime :date="interview.Date" format="DD. MMMM YYYY"></WtcDateTime>,
                                    </span>
                                    <span><WtcDateTime :date="interview.Time" format="HH:mm"></WtcDateTime></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag, useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import {
    getCombinedValue,
    getFlashQuotesChannel,
    getStrByLng,
    insertIf,
    IWtcMultiSelectItem,
    IWtcMultiSelectOptions,
    objectToSortedArray,
    RscCode,
    WtcButton,
    WtcChannel,
    WtcDateTime,
    WtcMultiSelect
} from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import Select from "primevue/select";
import { computed, ref } from "vue";

import checkActive from "../../assets/images/check-active.svg";
import checkEmpty from "../../assets/images/check-empty.svg";
import checkPartial from "../../assets/images/check-partial.svg";
import AthPdfHyperLink from "../../components/elements/pdfs/AthPdfHyperLink.vue";
import { ATH_PDF_KEYS } from "../../enums/ath.enums";
import { IFlashQuotesChannel } from "../../interfaces/ath.interfaces";
import { getPdfBinaryKey } from "../../utils/ath-reports.utils";
import { useAthRoutes } from "../../utils/ath-router.utils";

const { config, data } = storeToRefs(useFalconStore());
const flashQuotesChan = ref<IFlashQuotesChannel>({});
const selectedDisciplines = ref<IWtcMultiSelectItem[]>([]);

const dayFilter = ref(null);
const lngFilter = ref("");
const flashInterviewPdfName = computed(() =>
    getPdfBinaryKey(`${data.value.currentEventData.RscCode}_${ATH_PDF_KEYS.FLASH_INTERVIEW}`)
);
const { hrefToResult } = useAthRoutes();
const flashQuotesChannelName = computed(() => getFlashQuotesChannel(config.value.tournamentId));
const disciplines = computed(() =>
    objectToSortedArray(flashQuotesChan.value.Competitions)
        .filter((discipline) => discipline.Type === "E") // Only those are truely events and not units
        .map((discipline) => {
            return {
                label: discipline.Name,
                value: discipline.Code,
                Gender: new RscCode(discipline.Code).Gender
            };
        })
);

const menDisciplineList = computed(() => disciplines.value.filter((evt) => evt.Gender === "M"));
const womenDisciplineList = computed(() => disciplines.value.filter((evt) => evt.Gender === "W"));
const mixedDisciplineList = computed(() => disciplines.value.filter((evt) => evt.Gender !== "W" && evt.Gender !== "M"));

const groupedDisciplinesList = computed<IWtcMultiSelectOptions[]>(() => [
    {
        label: getStrByLng().LABEL_MEN_DISCIPLINE_SELECTION,
        items: menDisciplineList.value
    },
    {
        label: getStrByLng().LABEL_WOMEN_DISCIPLINE_SELECTION,
        items: womenDisciplineList.value
    },
    ...insertIf(mixedDisciplineList.value?.length > 0, {
        label: "Mixed",
        items: mixedDisciplineList.value
    })
]);

const languages = computed(() => objectToSortedArray(flashQuotesChan.value.Languages));
const translatedLanguages = computed(() =>
    languages.value.map((lng) => {
        return {
            ...lng,
            TranslatedName: useTranslatedProperty(lng, "Name")
        };
    })
);

const interviewsList = computed(() => objectToSortedArray(flashQuotesChan.value.Interviews, "Time", "desc"));

const interviewListByLng = computed(() => {
    if (lngFilter.value) {
        return objectToSortedArray(flashQuotesChan.value.Languages[lngFilter.value].ListTime, "ListIndex", "desc").map(
            (flashEntry) => flashQuotesChan.value.Interviews[flashEntry.Id]
        );
    }
    return interviewsList.value;
});
const selectedDisciplineValues = computed(() => selectedDisciplines.value.map((disciplineGrp) => disciplineGrp.value));

const renderedInterviews = computed(() =>
    interviewListByLng.value.filter((interview) => {
        const dayFiltered = dayFilter.value !== null ? dayFilter.value === interview.Date : true;
        const disciplineFiltered =
            selectedDisciplineValues.value.length > 0
                ? selectedDisciplineValues.value.includes(interview.Rsc?.ValueEvent)
                : true;

        return dayFiltered && disciplineFiltered;
    })
);
</script>

<style scoped lang="scss"></style>
