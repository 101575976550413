<template>
    <WtcChannel :channel-name="channelName">
        <template #slot__result>
            <div v-if="currentCompetitor || lastCompetitor" class="max-tablet:flex-col mb-2 flex gap-2">
                <WtcTransition>
                    <div :key="lastCompetitor.Id" class="bg-surface flex min-w-72 flex-col p-2">
                        <span class="text-brand-secondary text-sm">{{ $getStrByLng().COMPETITOR_STATUS_RUNNING }}</span>
                        <div class="flex items-center text-sm">
                            <FalconFlag class="text-xs" :nat-code="lastCompetitor.Nationality"></FalconFlag>
                            <WtcName class="font-normal_medium ml-1" :competitor-name="lastCompetitor"></WtcName>
                            <span class="text-content/40 ml-2">{{ lastCompetitor.Bib }}</span>
                        </div>
                        <span v-highlight="currentCompetitorResultValue" class="font-normal_bold text-xl">{{
                            lastCompetitorResultValue
                        }}</span>
                    </div>
                </WtcTransition>
                <WtcTransition>
                    <div :key="currentCompetitor.Id" class="bg-surface flex min-w-72 flex-col p-2">
                        <span class="text-brand-secondary text-sm">{{ $getStrByLng().COMPETITOR_STATUS_NEXT }}</span>
                        <div class="flex items-center text-sm">
                            <FalconFlag class="text-xs" :nat-code="currentCompetitor.Nationality"></FalconFlag>
                            <WtcName class="font-normal_medium ml-1" :competitor-name="currentCompetitor"></WtcName>
                            <span class="text-content/40 ml-2">{{ currentCompetitor.Bib }}</span>
                        </div>
                        <span v-highlight="currentCompetitorResultValue" class="font-normal_bold text-xl">{{
                            currentCompetitorResultValue
                        }}</span>
                    </div>
                </WtcTransition>
            </div>

            <div class="max-tablet:overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="sticky left-0 w-14 min-w-14">{{ $getStrByLng().HEADER_RANK }}</th>
                            <AthBibHeadColumn class="w-14 min-w-14"></AthBibHeadColumn>
                            <th class="w-20 min-w-20 text-left">{{ $getStrByLng().HEADER_NAT }}</th>
                            <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                            <th class="w-24 min-w-24"></th>
                            <th class="w-32 min-w-32 text-left">{{ $getStrByLng().HEADER_RESULT }}</th>
                        </tr>
                    </thead>
                    <WtcTransitionGroup name="list-sort" tag="tbody">
                        <tr v-for="competitor in sortedResultList as IAthCompetitorDetail[]" :key="competitor.Id">
                            <AthRankColumn class="sticky left-0" :competitor></AthRankColumn>
                            <AthBibColumn :competitor></AthBibColumn>
                            <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                            <AthNameColumn :competitor use-team-member></AthNameColumn>
                            <td>
                                <template v-if="competitor.Id === lastCompetitor?.Id">
                                    <div class="bg-surface-running inline-flex w-auto p-1 text-xs text-white">
                                        {{ $getStrByLng().COMPETITOR_STATUS_RUNNING }}
                                    </div>
                                </template>
                                <template v-if="competitor.Id === currentCompetitor?.Id">
                                    <div class="bg-surface-comingup inline-flex w-auto p-1 text-xs text-white">
                                        {{ $getStrByLng().COMPETITOR_STATUS_NEXT }}
                                    </div>
                                </template>
                            </td>
                            <AthComplexResultColumn :competitor></AthComplexResultColumn>
                        </tr>
                    </WtcTransitionGroup>
                </table>
            </div>
        </template>
    </WtcChannel>
</template>

<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { WtcChannel, WtcName, WtcTransition, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import { useAthTimingChannel } from "../../../composables/ath-data.composables";
import { AthDisciplineTypes } from "../../../enums/ath.enums";
import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";
import AthBibColumn from "../tables/AthBibColumn.vue";
import AthBibHeadColumn from "../tables/AthBibHeadColumn.vue";
import AthComplexResultColumn from "../tables/AthComplexResultColumn.vue";
import AthNameColumn from "../tables/AthNameColumn.vue";
import AthRankColumn from "../tables/AthRankColumn.vue";
const props = defineProps<{ rsc: string }>();
const { sortedResultList, timingData, channelName } = useAthTimingChannel(toRef(props, "rsc"));

const currentCompetitor = computed(() => timingData.value.CompetitorDetails?.[timingData.value.CurrentCompetitor?.Id]);
const lastCompetitor = computed(() => timingData.value.CompetitorDetails?.[timingData.value.LastCompetitor?.Id]);
const discType = computed(() => timingData.value.Stats?.Type);

const lastCompetitorResultValue = computed(() => {
    const curAttempt = timingData.value.LastCompetitor?.Attempt;

    if (discType.value === AthDisciplineTypes.WIDTH) {
        return lastCompetitor.value.Intermediate[curAttempt]?.Result ?? "";
    } else if (discType.value === AthDisciplineTypes.HEIGHT) {
        return `${timingData.value.Splits?.[curAttempt]?.Name} ${lastCompetitor.value.Intermediate[curAttempt]?.Result ?? ""}`;
    }
    return undefined;
});

const currentCompetitorResultValue = computed(() => {
    const curAttempt = timingData.value.CurrentCompetitor?.Attempt;

    if (discType.value === AthDisciplineTypes.WIDTH) {
        return "";
    } else if (discType.value === AthDisciplineTypes.HEIGHT) {
        return timingData.value.Splits?.[curAttempt]?.Name;
    }
    return undefined;
});
</script>
